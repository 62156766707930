@import '../../../css/common_vars';

.Card {
  width: 100%;
  padding: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;

  &.bright {
    color: $color_main;
    background-color: $color_bright;
  }
  &.main {
    background-color: $color_main;
  }
  &.accent {
    background-color: $color_accent;
  }
}