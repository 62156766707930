$scale: 4rem;

@keyframes animation-correct-explosion-circle {
  0% {
    opacity: 0;
    transform: scale3d(0.01, 0.01, 1);
  }
  10% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 1);
  }
  33.3333% {
    opacity: 1;
    transform: scale3d(0.5, 0.5, 1);
  }
  53.3333% {
    opacity: 0.7;
    transform: scale3d(1, 1, 1);
  }
  100% {
    opacity: 0;
    transform: scale3d(1.6, 1.6, 1);
  }
}

@keyframes animation-correct-explosion-line-1 {
  0% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale3d(0.1, 0.1, 1);
  }
  6% {
    opacity: 1;
    transform: translate3d(0, -0.2*$scale, 0) scale3d(0.5, 1, 1);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -3*$scale, 0) scale3d(0.7, 1.64, 1);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, -5.3*$scale, 0) scale3d(0.58, 0.36, 1);
  }
}

@keyframes animation-correct-explosion-line-2 {
  0% {
    opacity: 0;
    transform: translate3d(-6*$scale, -2*$scale, 0) rotateZ(45deg) scale3d(0.1, 0.1, 1);
  }
  16% {
    opacity: 1;
    transform: translate3d(-8.3*$scale, 0.2*$scale, 0) rotateZ(45deg) scale3d(0.5, 0.9, 1);
  }
  100% {
    opacity: 0;
    transform: translate3d(-11.7*$scale, 3.6*$scale, 0) rotateZ(45deg) scale3d(0.1, 0.1, 1);
  }
}

@keyframes animation-correct-explosion-line-3 {
  0% {
    opacity: 1;
    transform: translate3d(-2*$scale, -2*$scale, 0) rotateZ(-45deg) scale3d(0.1, 0.1, 1);
    animation-timing-function: ease;
  }
  6% {
    opacity: 1;
    transform: translate3d(-135.905px, -143.09px, 0) rotateZ(-45deg) scale3d(0.3, 0.68, 1);
  }
  40% {
    opacity: 1;
    transform: translate3d(150px, 142px, 0) rotateZ(-45deg) scale3d(0.5, 1.5, 1);
  }
  100% {
    opacity: 0;
    transform: translate3d(337px, 329px, 0) rotateZ(-45deg) scale3d(0.1, 0.5, 1);
  }
}

@keyframes animation-correct-explosion-heart-1 {
  0% {
    transform: scale3d(0.01, 0.01, 1);
    opacity: 1;
  }
  65.7143% {
    transform: scale3d(3, 3, 3);
    opacity: 1;
  }
  100% {
    transform: scale3d(2, 2, 3);
    opacity: 0;
  }
}

@keyframes animation-correct-explosion-heart-2-1 {
  0% {
    transform: translate3d(0, 0, 0) scale3d(0.001, 0.001, 1);
    opacity: 0;
  }
  14.2857% {
    transform: translate3d(0, 0, 0) scale3d(0.1, 0.1, 1);
    opacity: 1;
  }
  42.8571% {
    transform: translate3d(-1.2*$scale, -0.8*$scale, 0) scale3d(1, 1, 1);
    opacity: 1;
  }
  100% {
    transform: translate3d(-2.2*$scale, -1.4*$scale, 0) scale3d(1.5, 1.5, 1);
    opacity: 0;
  }
}

@keyframes animation-correct-explosion-heart-2-2 {
  0% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale3d(0.001, 0.001, 1);
    transform-origin: 0.5*$scale 0.5*$scale 0;
  }
  20% {
    opacity: 1;
    transform: translate3d(249px, 226px, 0) scale3d(0.5, 0.5, 1);
    transform-origin: 0.5*$scale 0.5*$scale 0;
  }
  100% {
    opacity: 0;
    transform: translate3d(350px, 340px, 0) scale3d(1, 1, 1);
    transform-origin: 1.5*$scale 1.5*$scale 0;
  }
}

@keyframes animation-correct-explosion-like-1 {
  0% {
    transform: translate3d(0, 0, 0) scale3d(0.001, 0.001, 1);
    opacity: 0;
  }
  21.6667% {
    transform: translate3d(1.7*$scale, -1.6*$scale, 0) scale3d(0.5, 0.5, 1);
    opacity: 1;
  }
  100% {
    transform: translate3d(2.6*$scale, -2.6*$scale, 0) scale3d(1, 1, 1);
    opacity: 0;
  }
}

@keyframes animation-correct-explosion-like-2 {
  0% {
    transform: translate3d(0, 0, 0) scale3d(0.001, 0.001, 1);
    opacity: 0;
  }
  37.1429% {
    transform: translate3d(0, 4.2*$scale, 0) scale3d(1, 1, 1);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 4.7*$scale, 0) scale3d(0.5, 0.5, 1);
    opacity: 0;
  }
}

@keyframes animation-correct-explosion-like-3 {
  0% {
    transform: translate3d(0, 0, 0) scale3d(0.001, 0.001, 1);
    opacity: 0;
  }
  38.7755% {
    transform: translate3d(-2.7*$scale, 1.1*$scale, 0) scale3d(0.7, 0.7, 1);
    opacity: 1;
  }
  100% {
    transform: translate3d(-4.1*$scale, 1.5*$scale, 0) scale3d(1.4, 1.4, 1);
    opacity: 0;
  }
}

.AnimationCorrectExplosion {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  z-index: 100;

  &.visible {
    display: block;

    .animation-correct-explosion {
      animation-play-state: running;
    }
  }

  .animation-correct-explosion {
    display: inline-block;
    position: absolute;
    width: 4*$scale;
    height: 4*$scale;
    top: -2*$scale;
    left: -2*$scale;
    transform-style: preserve-3d;

    pointer-events: none;

    animation-timing-function: linear;
    animation-duration: 400ms;
    animation-delay: 0ms;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
    animation-play-state: paused;
  }

  .animation-correct-explosion-circle {
    animation-name: animation-correct-explosion-circle;
    animation-duration: 600ms;
  }

  .animation-correct-explosion-heart-1 {
    animation-name: animation-correct-explosion-heart-1;
    animation-duration: 700ms;
  }

  .animation-correct-explosion-heart-2-1 {
    animation-name: animation-correct-explosion-heart-2-1;
    animation-duration: 700ms;
    top: -4*$scale;
    left: -4.5*$scale;
  }

  .animation-correct-explosion-heart-2-2 {
    animation-name: animation-correct-explosion-heart-2-2;
    animation-duration: 500ms;
    top: -1*$scale;
    left: -1*$scale;
  }

  .animation-correct-explosion-line-1 {
    animation-name: animation-correct-explosion-line-1;
    animation-duration: 500ms;
  }

  .animation-correct-explosion-line-2 {
    animation-name: animation-correct-explosion-line-2;
    animation-duration: 500ms;
    top: 0;
    left: 4*$scale;
  }

  .animation-correct-explosion-line-3 {
    animation-name: animation-correct-explosion-line-3;
    animation-duration: 500ms;
    top: 0;
    left: 0;
  }
  
  .animation-correct-explosion-like-1 {
    animation-name: animation-correct-explosion-like-1;
    animation-duration: 600ms;
    top: -3*$scale;
    left: -1*$scale;
  }

  .animation-correct-explosion-like-2 {
    animation-name: animation-correct-explosion-like-2;
    animation-duration: 700ms;
  }

  .animation-correct-explosion-like-3 {
    animation-name: animation-correct-explosion-like-3;
    animation-duration: 500ms;
  }
}
