@import '../../../css/common_vars';

.Button {
  cursor: pointer;
  user-select: none;

  display: inline-block;
  padding: 1rem;
  font-weight: 700;
  font-size: 2rem;

  transition: background-color 500ms;

  color: #ffffff;
  background-color: $color_clickable;

  &.big {
    padding: 2rem;
    font-size: 3rem;
  }

  &:hover {
    background-color: lighten($color_clickable, 5%);
  }
}