@import '../../../vars.scss';

.LoginScreen {
  padding-top: 15em;

  .OldCard {
    padding: 3em 0;

    .logotype {
      width: 25%;
      display: inline-block;
      vertical-align: top;
      padding-left: 1.5em;
      box-sizing: border-box;

      img {
        vertical-align: middle;
        box-sizing: border-box;
        width: 100%;
      }
    }

    .login-form {
      display: inline-block;
      width: 75%;
      vertical-align: top;
      box-sizing: border-box;
      padding-right: 1.5em;

      h1 {
        margin-top: 0;
      }

      fieldset {
        font-size: 1.2em;
        padding-bottom: 0;
      }

      .message {
        &.error {
          color: $color_error;
        }

        &.success {
          color: $color_success;
        }

        padding-top: 0.5em;
        height: 3em;
      }

      .login-button {
        width: 10em;

        .fa-spinner {
          margin-right: 0.5em;
        }
      }
    }
  }
}

.vertical {
  .LoginScreen {
    padding-top: 10em;
    .OldCard.login {
      padding: 2em 0;
      font-size: 1.5em;

      & > div {
        width: 100%;
        padding: 1em;
      }

      .logotype {
        padding-left: 3em;
        padding-right: 3em;
      }


      .login-form {
        label {
          width: 100%;
          font-size: 1.5em;
          text-align: center;
          margin-bottom: 0.5em;
        }

        input {
          width: 100%;
        }
      }
    }
  }
}