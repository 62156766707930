@import '../../../css/common_vars';

.outer.train > .middle {
  background-color: #00A8AB;
}

.TrainExercise {
  height: 100%;

  .cloud {
    position: absolute;
    width: 20em;
    opacity: 0.8;
  }

  #cloud-1 {
    top: 7em;
    left: -20em;
  }

  #cloud-2 {
    top: 28em;
    left: -60em;
  }

  #cloud-3 {
    top: 50em;
    left: -40em;
  }

  .InstructionCard {
    text-align: right;
    justify-content: flex-end;
    padding-right: 2rem;
  }

  .train-container {
    // height set in JS
    position: relative;
    z-index: 10;
    margin-top: 3rem;

    .animated-element {
      background-size: contain;
      background-repeat: no-repeat;

      display: block;
      z-index: 0;


      &.station {
        background-image: url('./img/train-station.svg');
        background-position-x: 60em;

        margin-left: -42rem;
        margin-bottom: 0.25rem;
        width: 200rem;
        height: 10rem;
      }

      &.train {
        background-image: url('./img/train.svg');
        background-position-y: bottom;
        margin-left: -20rem;
        margin-top: -10.3rem;
        width: 50rem;
        height: 10rem;
      }

      &.rails {
        background-image: url('./img/rails.svg');
        background-repeat: repeat-x;
        background-position-x: 60rem;

        margin-top: 0.25rem;
        margin-left: -50rem;
        width: 200rem;
        height: 0.5rem;
      }
    }
  }

  .answer-container {
    text-align: right;
    margin-top: 3rem;
    margin-right: 1rem;

    .answer {
      margin: 1rem 0 1rem 20%;
      padding: 2rem;
      width: 80%;
      box-sizing: border-box;

      text-align: right;
      justify-content: flex-end;

      p {
        margin: 0;
      }

      p.answer-count {
        font-size: 1.3rem;
      }

      p.answer-content {
        font-size: 2rem;
        font-weight: 700;
      }
    }

    .Button {
      padding: 1rem 2rem;
    }
  }
}

.vertical {
  .TrainExercise {

  }
}