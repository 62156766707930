@import '../../../vars.scss';

.SendResultsScreen {
  .message {
    height: 10em;
    margin-top: 10em;
    width: 80%;

    &.error {
      background: $gradient_error;
      border: 0.5em solid white;
      color: white;
    }

    &.success {
      background: $gradient_success;
      color: white;
    }

    .content {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5em;
      box-sizing: border-box;

      .fa-spinner {
        margin-right: 0.25em;
      }

      p {
        font-size: 1.5em;
      }
    }
  }
}